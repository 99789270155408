import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#2C3A4A] py-8">
      <div className="container mx-auto px-4 text-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-2">Contact</h3>
            <p>Heimlandr AB</p>
            <p>Org.nr 559377-4770</p>
            <p>Telefon: 0247-720 00</p>
            <p>Email: info@heimlandr.com</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Address</h3>
            <p>Helgabo 1</p>
            <p>565 97 Bottnaryd</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Websites</h3>
            <p><a href="https://www.heimlandr.io" className="hover:text-[#F4A261]">WWW.HEIMLANDR.IO</a></p>
            <p><a href="https://www.heimlandr.com" className="hover:text-[#F4A261]">WWW.HEIMLANDR.COM</a></p>
            <p><a href="https://www.heimlandr.org" className="hover:text-[#F4A261]">WWW.HEIMLANDR.ORG</a></p>
          </div>
        </div>
        <p className="mt-8">&copy; 2023 HEIMLANDR. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;