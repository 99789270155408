import React from 'react';

const Partners = () => {
  const partners = [
    { name: 'BIOBUILDS', url: 'https://www.biobuilds.com' },
    { name: 'HEIMLANDR.IO', url: 'https://www.heimlandr.io' },
    { name: 'AHLSELL', url: 'https://www.ahlsell.com' },
    { name: 'DSEG', url: 'https://www.dseg.se' },
    { name: 'HEIMLANDR.ORG', url: 'https://www.heimlandr.org' },
    { name: 'LAPLAND CONE', url: 'https://www.laplandcone.se' },
    { name: 'PILLARS GROUP', url: 'https://www.pillarsgroup.se' },
    { name: 'PURITECH', url: 'https://www.puritech.se' },
    { name: 'MYKROND', url: 'https://www.mykrond.se' },
    { name: 'NOMADIC HOMES', url: 'https://nomadic-homes.com/' },
    { name: 'EKO BANKEN', url: 'https://www.ekobanken.se' },
  ];

  return (
    <section id="partners" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Partners</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {partners.map((partner, index) => (
            <a key={index} href={partner.url} target="_blank" rel="noopener noreferrer" className="partner-link bg-[#3E5641] p-4 rounded-lg shadow-lg flex items-center justify-center hover:bg-[#4A6D50] transition duration-300">
              <span className="text-lg font-bold text-center">{partner.name}</span>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;