import React, { useState } from 'react';
import { X } from 'lucide-react';

const Foundation = () => {
  const [showStatutes, setShowStatutes] = useState(false);

  return (
    <section id="foundation" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">HEIMLANDR Foundation</h2>
        <div className="bg-[#3E5641] p-8 rounded-lg shadow-lg">
          <p className="text-lg mb-4">The HEIMLANDR Foundation is dedicated to promoting sustainable living and community development worldwide. We support initiatives that align with our core values of sustainability, innovation, and community.</p>
          <a href="#" className="inline-block bg-[#8B5E3C] text-[#E6D3B1] py-2 px-6 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 mb-6">Learn More About Our Foundation</a>
          <p className="text-lg mb-4">Community development by HEIMLANDR is done behind strong biological statutes. As our foundation is the cornerstone of all eco-village projects, we ensure that every initiative aligns with ecological sustainability and environmental stewardship. Our foundation's statutes are the blueprint of how we develop.</p>
          <button 
            onClick={() => setShowStatutes(true)} 
            className="text-[#F4A261] hover:text-[#E6D3B1] transition duration-300"
          >
            View Statutes
          </button>
        </div>
      </div>

      {showStatutes && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#2E4D3A] p-8 rounded-lg shadow-lg max-w-2xl max-h-[80vh] overflow-y-auto relative">
            <button 
              onClick={() => setShowStatutes(false)} 
              className="absolute top-4 right-4 text-[#E6D3B1] hover:text-[#F4A261]"
            >
              <X size={24} />
            </button>
            <h3 className="text-2xl font-bold mb-4">STIFTELSENS SYFTE OCH ÄNDAMÅL</h3>
            <p className="mb-4">Stiftelsens syfte är att verka för pånyttfödelsen av en respektfull och ömsesidig relation med Moder Jord och alla hennes varelser samt ett omhändertagande av elementernas renhet. Stiftelsen ämnar stödja personer och organisationer vars insatser verkar för natur och människa i harmoni.</p>
            <h4 className="text-xl font-bold mb-2">Stiftelsens ändamål är att:</h4>
            <ul className="list-disc pl-6 space-y-2">
              <li>Främja insatser vars fokus är i samklang med ett holistiskt perspektiv, det vill säga betonar vikten av helheten och det ömsesidiga beroendet av dess delar.</li>
              <li>Främja insatser som motverkar utanförskap, ensamhet eller liknande, gärna genom naturens kraft.</li>
              <li>Främja insatser som fokuserar på att låta människan bestämma om sin egen hälsa med exempelvis alternativ behandling och medicin främst i ett holistiskt perspektiv.</li>
              <li>Främja utbildning, utveckling och forskning i enlighet med syfte och ändamål.</li>
              <li>Främja insatser med ett förenande kulturellt perspektiv.</li>
              <li>Främja insatser med hållbar energiproduktion.</li>
              <li>Främja insatser som etablerar självförsörjande system och eliminerar avfall/förorening genom permakultur eller liknande.</li>
              <li>Främja insatser med fokus hållbarhet, det vill säga goda sociala livsbetingelser utan att människans existens och förverkligande i samhället utarmar ekosystemens funktioner, så som cirkulär ekonomi eller liknande.</li>
              <li>Samverka med andra stiftelser och organisationer.</li>
              <li>Dela ut stipendium i enlighet med syfte och ändamål.</li>
              <li>Driva egna insatser i enlighet med syfte och ändamål.</li>
              <li>Hyra, köpa, äga, driva och sälja mark eller fastigheter som används i syfte att verka för stiftelsens syfte och ändamål.</li>
            </ul>
            <p className="mt-4">Stiftelsen är fristående, partipolitiskt och religiöst obunden.</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Foundation;