import React, { useState } from 'react';
import ProjectForm from './ProjectForm';

const Projects = () => {
  const [showProjectForm, setShowProjectForm] = useState(false);

  return (
    <section id="projects" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Projects</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
          <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <img src="https://i.imgur.com/ONW2Ckq.jpeg" alt="15 ECO Village Developments" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-bold mb-2">ECO Village Developments With Puritech</h3>
            <p className="text-sm sm:text-base">In collaboration with Puritech and Swedish municipalities, we're currently planning and developing 17 ECO villages for workers and residents all around Sweden. These sustainable communities integrate cutting-edge eco-friendly technologies with thoughtful urban planning to create harmonious living spaces that benefit both residents and the environment.</p>
          </article>
          <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <img src="https://i.imgur.com/gkp6lPb.jpeg" alt="Smart Home Community" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-bold mb-2">Ödeshög ECO-Colony Concept</h3>
            <p className="text-sm sm:text-base">A growing community of 20 houses featuring a village sauna, greenhouse, earth cellar, outdoor kitchen, showers, community spaces, and tiny sleep huts for overnight stays. Situated within a nature reserve, this development offers a unique, eco-friendly living experience amidst pristine, natural surroundings.</p>
          </article>
          <article className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <img src="https://i.imgur.com/d2MhZ5j.png" alt="Eco Village" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-bold mb-2">SOLFORS EKO-BY DEVELOPMENT</h3>
            <p className="text-sm sm:text-base">An off-grid, 110-hectare eco-village nestled 10 kilometers into the wilderness. HEIMLANDR has transformed this former "Swedish torp" into a self-sustaining, year-round community focused on sustainable living, off-grid independence, and eco-tourism.</p>
          </article>
        </div>
        <div className="text-center">
          <button
            onClick={() => setShowProjectForm(true)}
            className="bg-[#8B5E3C] text-[#E6D3B1] py-3 px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-base sm:text-lg btn-glow"
          >
            Your Project
          </button>
        </div>
      </div>
      {showProjectForm && <ProjectForm onClose={() => setShowProjectForm(false)} />}
    </section>
  );
};

export default Projects;