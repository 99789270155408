import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Hero from './components/Hero';
import Fundamentals from './components/Fundamentals';
import Projects from './components/Projects';
import Services from './components/Services';
import Team from './components/Team';
import Foundation from './components/Foundation';
import Partners from './components/Partners';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <meta name="keywords" content={t('meta.keywords')} />
        <meta property="og:title" content={t('meta.ogTitle')} />
        <meta property="og:description" content={t('meta.ogDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.heimlandr.com" />
      </Helmet>
      <div className="min-h-screen bg-[#2E4D3A] text-[#E6D3B1] font-['Montserrat', sans-serif]">
        <Header />
        <Hero />
        <Fundamentals />
        <Projects />
        <Services />
        <Team />
        <Foundation />
        <Partners />
        <Contact />
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

export default App;