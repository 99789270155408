import React from 'react';
import { Leaf, Zap, Shield } from 'lucide-react';

const Fundamentals = () => {
  return (
    <section id="fundamentals" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Fundamentals</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Leaf className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">Sustainability</h3>
            <p>We prioritize eco-friendly practices and materials in all our projects.</p>
          </div>
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Zap className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">Innovation</h3>
            <p>Cutting-edge technology and design for modern, efficient living spaces.</p>
          </div>
          <div className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
            <Shield className="w-12 h-12 mb-4 text-[#F4A261]" />
            <h3 className="text-2xl font-bold mb-2">Community</h3>
            <p>Building strong, supportive communities for a better quality of life.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Fundamentals;