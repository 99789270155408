import React from 'react';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="hero-section flex items-center justify-center text-white min-h-screen">
      <div className="hero-overlay"></div>
      <div className="sun-flare"></div>
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-glow font-['Bungee Hairline']">{t('hero.title')}</h1>
        <p className="text-lg sm:text-xl md:text-2xl mb-8 text-glow">{t('hero.subtitle')}</p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a href="#fundamentals" className="w-full sm:w-auto bg-[#8B5E3C] text-[#E6D3B1] py-3 px-6 sm:px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-sm sm:text-base md:text-lg btn-glow" aria-label="Learn more about our fundamentals">{t('hero.learnMore')}</a>
          <a href="#projects" className="w-full sm:w-auto bg-[#8B5E3C] text-[#E6D3B1] py-3 px-6 sm:px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300 text-sm sm:text-base md:text-lg btn-glow" aria-label="View our projects">{t('hero.ourProjects')}</a>
        </div>
      </div>
    </section>
  );
};

export default Hero;