import React from 'react';
import { Home, Battery, Globe, Users, Sun, Compass, Database, Vote, DollarSign } from 'lucide-react';

const Services = () => {
  const services = [
    { icon: Home, title: "Eco-village development and planning", description: "Creating sustainable communities with a focus on eco-friendly practices and long-term viability." },
    { icon: Battery, title: "Sustainable housing solutions", description: "Designing and building homes using sustainable materials and energy-efficient systems." },
    { icon: Globe, title: "Off-grid living infrastructure", description: "Implementing systems for water, energy, and waste management to support off-grid living." },
    { icon: Users, title: "Community building and design", description: "Fostering communal living through thoughtful design, shared spaces, and collaborative environments." },
    { icon: Sun, title: "Renewable energy integration", description: "Incorporating solar, wind, and other renewable energy sources to power eco-villages." },
    { icon: Compass, title: "Eco-tourism development", description: "Developing tourism opportunities that emphasize environmental sustainability and community engagement." },
    { icon: Database, title: "Tokenization of eco-villages", description: "Using blockchain technology to tokenize eco-village assets, enabling fractional ownership and investment." },
    { icon: Vote, title: "Blockchain-powered governance for communities", description: "Implementing decentralized governance models for eco-villages, allowing community members to participate in decision-making through blockchain." },
    { icon: DollarSign, title: "DeFi solutions for sustainable projects", description: "Leveraging decentralized finance (DeFi) to fund and support eco-friendly projects, offering transparency and access to global investors." },
  ];

  return (
    <section id="services" className="py-20 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <article key={index} className="bg-[#3E5641] p-6 rounded-lg shadow-lg">
              <service.icon className="w-12 h-12 mb-4 text-[#F4A261]" aria-hidden="true" />
              <h3 className="text-xl sm:text-2xl font-bold mb-2">{service.title}</h3>
              <p className="text-sm sm:text-base">{service.description}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;