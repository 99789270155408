import React, { useState } from 'react';
import { X } from 'lucide-react';

const ProjectForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectType: '',
    projectSize: '',
    location: '',
    budget: '',
    timeline: '',
    sustainabilityFeatures: [],
    fundingStatus: '',
    additionalInfo: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' 
        ? (checked 
          ? [...prevState.sustainabilityFeatures, value]
          : prevState.sustainabilityFeatures.filter(item => item !== value))
        : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Here you would typically send the data to a server
    alert('Thank you for submitting your project idea! We will be in touch soon.');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2E4D3A] p-8 rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-[#E6D3B1] hover:text-[#F4A261]"
        >
          <X size={24} />
        </button>
        <h2 className="text-3xl font-bold mb-6 text-center">Submit Your Project Idea</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="projectType" className="block mb-2">Project Type</label>
            <select
              id="projectType"
              name="projectType"
              value={formData.projectType}
              onChange={handleChange}
              className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              required
            >
              <option value="">Select a project type</option>
              <option value="eco-village">Eco-Village</option>
              <option value="sustainable-housing">Sustainable Housing</option>
              <option value="off-grid-living">Off-Grid Living</option>
              <option value="community-development">Community Development</option>
              <option value="renewable-energy">Renewable Energy</option>
              <option value="eco-tourism">Eco-Tourism</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="projectSize" className="block mb-2">Project Size</label>
              <input
                type="text"
                id="projectSize"
                name="projectSize"
                value={formData.projectSize}
                onChange={handleChange}
                placeholder="e.g., 5 hectares, 20 housing units"
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              />
            </div>
            <div>
              <label htmlFor="location" className="block mb-2">Location</label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="City, Country"
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="budget" className="block mb-2">Estimated Budget</label>
              <input
                type="text"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                placeholder="e.g., $500,000 - $1,000,000"
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              />
            </div>
            <div>
              <label htmlFor="timeline" className="block mb-2">Expected Timeline</label>
              <input
                type="text"
                id="timeline"
                name="timeline"
                value={formData.timeline}
                onChange={handleChange}
                placeholder="e.g., 2-3 years"
                className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              />
            </div>
          </div>

          <div>
            <label className="block mb-2">Sustainability Features</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {['Solar Power', 'Wind Power', 'Rainwater Harvesting', 'Composting', 'Permaculture', 'Natural Building Materials', 'Waste Recycling', 'Greywater Systems'].map((feature) => (
                <label key={feature} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="sustainabilityFeatures"
                    value={feature}
                    checked={formData.sustainabilityFeatures.includes(feature)}
                    onChange={handleChange}
                    className="form-checkbox text-[#F4A261]"
                  />
                  <span>{feature}</span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="fundingStatus" className="block mb-2">Funding Status</label>
            <select
              id="fundingStatus"
              name="fundingStatus"
              value={formData.fundingStatus}
              onChange={handleChange}
              className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              required
            >
              <option value="">Select funding status</option>
              <option value="fully-funded">Fully Funded</option>
              <option value="partially-funded">Partially Funded</option>
              <option value="seeking-funding">Seeking Funding</option>
              <option value="not-started">Not Started</option>
            </select>
          </div>

          <div>
            <label htmlFor="additionalInfo" className="block mb-2">Additional Information</label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              rows={4}
              className="w-full p-2 rounded-lg bg-[#3E5641] text-[#E6D3B1]"
              placeholder="Please provide any additional details about your project idea..."
            ></textarea>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="bg-[#8B5E3C] text-[#E6D3B1] py-3 px-8 rounded-full font-bold hover:bg-[#F4A261] hover:text-[#2C3A4A] transition duration-300"
            >
              Submit Project Idea
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectForm;