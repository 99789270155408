import React from 'react';
import { Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();
  const teamMembers = [
    {
      name: "Fredrik Brunnberg",
      title: "Head of Operations & Development Architect",
      description: "Expert in off-grid systems and sustainable architecture."
    },
    {
      name: "Moa Grundemark",
      title: "Chief Financial Officer",
      description: "Oversees financial planning and eco-friendly investment practices."
    },
    {
      name: "Fredrik Ejemo",
      title: "Builder Relations & Team Development Manager",
      description: "Manages construction partnerships and fosters team cohesion."
    },
    {
      name: "Julia Carroll",
      title: "Head of Communications & Administrative Operations",
      description: "Leads communication strategies and administrative functions."
    }
  ];

  return (
    <section id="team" className="py-12 bg-[#2C3A4A]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">{t('team.title')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {teamMembers.map((member, index) => (
            <div key={index} className="bg-[#3E5641] p-4 rounded-lg shadow-lg">
              <Users className="w-8 h-8 mb-2 text-[#F4A261]" />
              <h3 className="text-lg font-bold mb-1">{member.name}</h3>
              <h4 className="text-sm font-semibold mb-2 text-[#F4A261]">{member.title}</h4>
              <p className="text-xs">{member.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;