import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import App from './App.tsx'
import './index.css'
import './i18n'

const root = document.getElementById('root');

if (root) {
  try {
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error rendering the app:', error);
    root.innerHTML = '<div style="color: white; padding: 20px;">An error occurred while loading the application. Please check the console for more details.</div>';
  }
} else {
  console.error('Root element not found');
}