import React, { useState, useEffect } from 'react';
import { Globe, Wallet, ChevronDown, Menu, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showWalletPopup, setShowWalletPopup] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'sv', name: 'Svenska' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleWalletPopup = () => {
    setShowWalletPopup(!showWalletPopup);
    setShowLanguageDropdown(false);
    setShowMobileMenu(false);
  };

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
    setShowWalletPopup(false);
    setShowMobileMenu(false);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setShowLanguageDropdown(false);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowWalletPopup(false);
    setShowLanguageDropdown(false);
  };

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-[#2C3A4A] bg-opacity-90' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <img src="https://i.imgur.com/bysF9Ka.png" alt="HEIMLANDR Logo" className="h-6 sm:h-8" />
        <nav className="hidden lg:block" aria-label="Main navigation">
          <ul className="flex space-x-3 text-sm">
            <li><a href="#fundamentals" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.fundamentals')}</a></li>
            <li><a href="#projects" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.projects')}</a></li>
            <li><a href="#services" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.services')}</a></li>
            <li><a href="#team" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.team')}</a></li>
            <li><a href="#foundation" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.foundation')}</a></li>
            <li><a href="#partners" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.partners')}</a></li>
            <li><a href="#contact" className="hover:text-[#F4A261] transition-all duration-300">{t('nav.contact')}</a></li>
          </ul>
        </nav>
        <div className="hidden lg:flex items-center space-x-3">
          <div className="relative">
            <button
              onClick={toggleLanguageDropdown}
              className="flex items-center text-[#E6D3B1] hover:text-[#F4A261] transition-all duration-300"
              aria-label="Change language"
            >
              <Globe className="w-5 h-5 mr-1" />
              <ChevronDown className="w-3 h-3" />
            </button>
            {showLanguageDropdown && (
              <div className="absolute right-0 mt-2 w-40 bg-[#3E5641] rounded-lg shadow-lg z-20">
                <ul className="py-2">
                  {languages.map((lang) => (
                    <li key={lang.code}>
                      <button
                        className="w-full text-left px-4 py-2 text-sm text-[#E6D3B1] hover:bg-[#4A6D50] transition-all duration-300"
                        onClick={() => changeLanguage(lang.code)}
                      >
                        {lang.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="relative">
            <Wallet
              className="w-5 h-5 text-[#E6D3B1] cursor-pointer hover:text-[#F4A261] transition-all duration-300"
              onClick={toggleWalletPopup}
              aria-label="Open wallet"
            />
            {showWalletPopup && (
              <div className="absolute right-0 mt-2 w-64 bg-[#3E5641] text-[#E6D3B1] p-4 rounded-lg shadow-lg z-20">
                <p className="text-sm">
                  {t('wallet.popup')}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:hidden flex items-center space-x-3">
          <button onClick={toggleLanguageDropdown} className="text-[#E6D3B1] hover:text-[#F4A261] z-50 relative" aria-label="Change language">
            <Globe size={20} />
          </button>
          <button onClick={toggleWalletPopup} className="text-[#E6D3B1] hover:text-[#F4A261] z-50 relative" aria-label="Open wallet">
            <Wallet size={20} />
          </button>
          <button onClick={toggleMobileMenu} className="text-[#E6D3B1] hover:text-[#F4A261] z-50 relative" aria-label="Toggle mobile menu">
            {showMobileMenu ? <X size={20} /> : <Menu size={20} />}
          </button>
        </div>
      </div>
      {showMobileMenu && (
        <nav className="lg:hidden bg-[#2C3A4A] bg-opacity-95 py-4 absolute top-full left-0 right-0 z-40" aria-label="Mobile navigation">
          <ul className="flex flex-col items-center space-y-4">
            <li><a href="#fundamentals" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.fundamentals')}</a></li>
            <li><a href="#projects" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.projects')}</a></li>
            <li><a href="#services" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.services')}</a></li>
            <li><a href="#team" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.team')}</a></li>
            <li><a href="#foundation" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.foundation')}</a></li>
            <li><a href="#partners" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.partners')}</a></li>
            <li><a href="#contact" className="hover:text-[#F4A261] transition-all duration-300" onClick={toggleMobileMenu}>{t('nav.contact')}</a></li>
          </ul>
        </nav>
      )}
      {showLanguageDropdown && (
        <div className="lg:hidden absolute top-full left-0 right-0 bg-[#3E5641] z-40">
          <ul className="py-2">
            {languages.map((lang) => (
              <li key={lang.code}>
                <button
                  className="w-full text-left px-4 py-2 text-sm text-[#E6D3B1] hover:bg-[#4A6D50] transition-all duration-300"
                  onClick={() => changeLanguage(lang.code)}
                >
                  {lang.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {showWalletPopup && (
        <div className="lg:hidden absolute top-full left-0 right-0 bg-[#3E5641] text-[#E6D3B1] p-4 z-40">
          <p className="text-sm">
            {t('wallet.popup')}
          </p>
        </div>
      )}
    </header>
  );
};

export default Header;